import { Button, Card, Form, Input } from "antd";
import React from "react";
import { useAuth } from "../../../context/auth-context";
import { Navigate } from "react-router-dom";

const LoginPage = () => {
  const authValue = useAuth();
  const { loginHandler, loginLoading } = authValue;

  const onFinish = (values) => {
    loginHandler(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (authValue?.token) {
    return <Navigate to={"/"} />;
  }

  return (
    <div className="th-flex-center" style={{ height: "100vh" }}>
      <Card
        className="rounded-0 "
        style={{ minWidth: "25.33rem", padding: "1rem" }}
      >
        <h3>Sign In</h3>
        <p className="text-secondary">Login and password</p>
        <Form
          name="loginForm"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Username"
            name="email"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-100"
              loading={loginLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginPage;
