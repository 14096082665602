import React from "react";
import { Footer as AntdFooter } from "antd/es/layout/layout";
const Footer = () => {
  return (
    <React.Fragment>
      <AntdFooter className="pt-1">
        <div className="text-center">
          Copyright © 2023 K12 Techno Services Pvt. Ltd
        </div>
      </AntdFooter>
    </React.Fragment>
  );
};

export default Footer;
