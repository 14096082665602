import { createContext, useContext, useReducer } from "react";

const DataContext = createContext();

const initialData = {
  currentAddress: JSON.parse(localStorage.getItem("current_address")),
};

const dataReducer = (state, action) => {
  switch (action.type) {
    case "addressUpdate":
      localStorage.setItem("current_address", JSON.stringify(action.payload));
      return { ...state, currentAddress: action.payload };
    default:
      return {};
  }
};

const DataProvider = ({ children }) => {
  const [dataState, dispatch] = useReducer(dataReducer, initialData);
  return (
    <DataContext.Provider value={{ dispatch, dataState }}>
      {children}
    </DataContext.Provider>
  );
};

const useData = () => useContext(DataContext);

export { useData, DataProvider };
